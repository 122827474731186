<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <el-button
        type="primary"
        @click="showAdd"
      >+新增</el-button>
      <!-- <div class="secondrow"> -->
      <!-- 店铺搜索 -->
      <div class="input">
        <!-- 输入框，尾部带按钮 -->
        账号/姓名：
        <el-input
          placeholder="请输入账号/姓名"
          v-model="queryInfo.userName"
          clearable
          @clear="getUserSys"
          @change="searchUserSys"
        >
          <el-button
            slot="append"
            @click="searchUserSys"
          >搜索</el-button>
        </el-input>
      </div>
      <div class="code">
        <!-- 输入框，尾部带按钮 -->
        会员卡号：
        <el-input
          placeholder="请输入会员卡号"
          v-model="queryInfo.cardNo"
          clearable
          @clear="getUserSys"
          @change="searchUserSys"
        >
          <el-button
            slot="append"
            @click="searchUserSys"
          >搜索</el-button>
        </el-input>
      </div>
      <!-- 会员类型筛选 -->
      <div class="type">
        会员类型：
        <el-select
          v-model="queryInfo.memberGrade"
          placeholder="全部"
          clearable
          @clear="getUserSys"
          @change="searchUserSys"
        >
          <el-option
            v-for="item in forPeopleOpt"
            :key="item.id"
            :label="item.gradeName"
            :value="item.gradeName"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 状态筛选 -->
      <div class="status">
        使用状态：
        <el-select
          v-model="queryInfo.status"
          placeholder="全部"
          clearable
          @clear="getUserSys"
          @change="searchUserSys"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="exportClass">
        <el-button
          size="small"
          type="primary"
          @click="exportInfo"
        >导出表</el-button>
      </div>
      <!-- </div> -->
      <!-- 会员列表 -->
      <el-table
        :data="userList"
        style="width: 100%"
        border
        stripe
      >
        <el-table-column
          prop="mobile"
          label="账号"
        > </el-table-column>
        <el-table-column
          prop="cardNo"
          label="会员卡号"
        > </el-table-column>
        <el-table-column
          prop="memberCardNo"
          label="会员卡内码"
        > </el-table-column>
        <el-table-column
          label="昵称"
          prop="userName"
        ></el-table-column>
        <el-table-column
          label="可用积分"
          prop="remainPoint"
        ></el-table-column>
        <el-table-column
          prop="memberGrade"
          label="会员类型"
        >
        </el-table-column>
        <el-table-column
          label="有效时间"
          width="160px"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.memberStartTime }} - {{ scope.row.memberEndTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="50px"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status === 0">
              <div>启用</div>
            </div>
            <div v-else-if="scope.row.status === 1">
              <div>禁用</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="操作"
          width="400"
        >
          <template slot-scope="scope">
            <div style="display:flex;">
              <el-button
                size="mini"
                v-if="scope.row.status===1"
                type="success"
                @click="starUser(scope.row)"
              >启用</el-button>
              <!-- 删除按钮 -->
              <div v-else-if="scope.row.status === 0">
                <el-button
                  size="mini"
                  type="primary"
                  @click="editUser(scope.row)"
                >修改</el-button>
                <el-button
                  style="margin-left:20px"
                  size="mini"
                  type="danger"
                  @click="stopUser(scope.row)"
                >停用</el-button>
              </div>
              <el-button
                style="margin-left:10px"
                size="mini"
                type="primary"
                @click="interDetail(scope.row)"
              >积分明细</el-button>
              <el-button
                style="margin-left:10px"
                size="mini"
                type="primary"
                @click="orderDetail(scope.row)"
              >订单明细</el-button>
              <!-- 删除按钮 -->
              <el-button
                style="margin-left: 10px;"
                size="mini"
                type="danger"
                @click="deleteById(scope.row.id)"
              >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :total="total"
        @size-change="handleSizeChange"
        :current-page="queryInfo.pageNo"
        :page-size="queryInfo.limit"
        :page-sizes="[15, 30, 50, 100]"
        @current-change="handleCurrentChange"
        layout="total,sizes,prev,pager,next,jumper"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      @close="clearAddInfo"
      :title="title"
      :visible.sync="DialogVisible"
      width="700px"
    >
      <el-form
        :rules="userRules"
        ref="userFormRef"
        :model="userForm"
        label-width="100px"
      >
        <el-form-item
          label="账号"
          prop="mobile"
        >
          <el-input
            v-model="userForm.mobile"
            placeholder="请输入账号"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="等级"
          prop="memberId"
        >
          <el-select
            v-model="userForm.memberId"
            placeholder="全部"
            clearable
          >
            <el-option
              v-for="item in forPeopleOpt"
              :key="item.id"
              :label="item.gradeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="有效期效"
          prop="memberEndTime"
        >
          <el-date-picker
            v-model="effectiveDate"
            type="datetimerange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changeGrantTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="卡号">
          <el-input
            placeholder="请输入卡号"
            v-model="userForm.cardNo"
          ></el-input>
        </el-form-item>
        <el-form-item label="会员卡内码">
          <el-input
            placeholder="请输入会员卡内码"
            v-model="userForm.memberCardNo"
          ></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input
            v-model="userForm.userName"
            placeholder="请输入昵称"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select
            v-model="userForm.sex"
            placeholder="全部"
            clearable
          >
            <el-option
              v-for="item in sex"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="DialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    // 手机号校验规则
    return {
      disabled: false,
      userList: [],
      queryInfo: {
        limit: 15,
        pageNo: 1,
        userName: '',
        status: '',
        memberGrade: '',
        cardNo: ''
      },
      effectiveDate: [], // 有效期限
      userForm: {
        mobile: '', // 手机号
        memberId: '',
        memberStartTime: '', // 结束时间
        memberEndTime: '', // 开始时间
        cardNo: '', // 卡号
        memberCardNo: '', // 卡内码
        userName: '', // 用户名称
        sex: ''
      },
      total: 0,
      forPeopleOpt: [],
      statusList: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '启用'
        },
        {
          value: 1,
          label: '禁用'
        }
      ],
      sex: [
        {
          value: 0,
          label: '女'
        },
        {
          value: 1,
          label: '男'
        }
      ],
      title: '',
      // 新增对话框
      DialogVisible: false,
      submitType: '',
      userId: '',
      editEcho: {},
      userRules: {
        mobile: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        memberId: [
          { required: true, message: '请选择等级', trigger: 'blur' }
        ],
        memberEndTime: [
          { required: true, message: '请选择有效期限', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getUserSys()
    this.getMemberLevel()
  },
  methods: {
    searchUserSys () {
      this.queryInfo.pageNo = 1
      this.getUserSys()
    },
    async getUserSys () {
      const { data: res } = await this.$http.get('/userManager/list', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.userList = res.data.data
      // console.log(this.userList, 111)
      this.total = res.data.total
    },
    // 监听 limit 改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getUserSys()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getUserSys()
    },
    // 会员等级
    async getMemberLevel () {
      const { data: res } = await this.$http.get('/memberGrade/list')
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.forPeopleOpt = res.data.data
    },
    changeGrantTime () {
      // 赋值时间
      this.userForm.memberStartTime = this.effectiveDate[0]
      this.userForm.memberEndTime = this.effectiveDate[1]
    },
    showAdd () {
      this.disabled = false
      this.title = '新增会员'
      this.submitType = 'add'
      this.DialogVisible = true
    },
    async editUser (row) {
      this.disabled = true
      this.title = '修改会员'
      this.submitType = 'edit'
      this.userId = row.id
      this.DialogVisible = true
      const { data: res } = await this.$http.get(`/userManager/${this.userId}`)
      if (res.code !== 0) return this.$message.error(res.message)
      this.userForm = res.data
      this.effectiveDate = [this.userForm.memberStartTime, this.userForm.memberEndTime]
    },
    submit () {
      this.$refs.userFormRef.validate(async valid => {
        if (!valid) return
        if (this.submitType === 'add') {
          const { data: res } = await this.$http.post('/userManager', this.userForm)
          // console.log(res, '111')
          if (res.code !== 0) return this.$message.error(res.message)
          // console.log(res, '1344')
          this.$message.success('新增成功')
          this.DialogVisible = false
          this.getUserSys()
        } else if (this.submitType === 'edit') {
          const { data: res } = await this.$http.put(`/userManager/${this.userId}`, this.userForm)
          if (res.code !== 0) return this.$message.error(res.message)
          this.$message.success('修改成功')
          this.DialogVisible = false
          this.getUserSys()
        }
      })
    },
    // 清空弹框
    clearAddInfo () {
      this.userForm = {}
      this.effectiveDate = []
    },
    // 启用
    async starUser (row) {
      row.status = 0
      console.log(row)
      const { data: res } = await this.$http.put(
        `/userManager/status/${row.id}`,
        row
      )
      if (res.code !== 0) {
        this.$message.error('修改状态失败')
      }
      this.$message.success('修改状态成功')
      this.getUserSys()
    },
    // 停用
    async stopUser (row) {
      console.log(row)
      row.status = 1
      const { data: res } = await this.$http.put(
        `/userManager/status/${row.id}`,
        row
      )
      if (res.code !== 0) {
        this.$message.error('修改状态失败')
      }
      this.$message.success('修改状态成功')
      this.getUserSys()
    },
    // 删除
    async deleteById (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该会员用户，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`userManager/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除会员失败')
      }
      this.$message.success('删除会员成功')
      this.getUserSys()
    },
    // 积分明细跳转
    interDetail (row) {
      this.$router.push({ path: '/integral', query: { mobile: row.mobile } })
    },
    // 订单明细
    orderDetail (row) {
      this.$router.push({ path: '/orderList', query: { mobile: row.mobile } })
    },
    exportInfo () {
      this.$http({
        url: '/userManager/export',
        method: 'post',
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          // 接口参数
        }
      }).then((res) => {
        // 创建一个隐藏的a连接，
        const link = document.createElement('a')
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        // 设置连接
        link.href = URL.createObjectURL(blob)
        link.download = '会员导出.xls'
        document.body.appendChild(link)
        // 模拟点击事件
        link.click()
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.input {
  display: flex;
  width: 380px;
  align-items: center;
  .el-input {
    width: 220px;
  }
}
.code {
  position: relative;
  margin-left: 310px;
  margin-top: -40px;
  .el-input {
    width: 240px;
  }
}
.type {
  position: relative;
  margin-left: 660px;
  margin-top: -40px;
  .el-select {
    width: 100px;
  }
}
.status {
  position: relative;
  margin-left: 850px;
  margin-top: -40px;
  .el-select {
    width: 80px;
  }
}
.exportClass {
  position: relative;
  margin-left: 1020px;
  margin-top: -37px;
}
</style>
